@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 400;
    src: local('Euclid Circular A Regular'),
      url('assets/fonts/Euclid\ Circular\ A\ Regular.woff') format('woff');
}

@font-face {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 600;
    src: local('Euclid Circular A Bold'),
      url('assets/fonts/Euclid\ Circular\ A\ Bold.woff') format('woff');
}